import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const DoPobraniaTiles = () => {
  return (
    <>
      <div className="subtitle is-6 is-family-secondary pt-6 mb-5 has-text-weight-light has-letter-spacing-1">
        MATERIAŁY DO POBRANIA
      </div>

      <a
        href="do-pobrania/Wrutkowski_artykuly.pdf"
        className="has-text-centered"
        target="_blank"
      >
        <div class="columns has-text-centered is-vcentered is-mobile mb-6 is-gold-link">
          <div class="column is-3">
            <StaticImage
              src="../images/pdf-1.jpg"
              alt="Artykyły PDF"
              objectFit="contain"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
          </div>
          <div class="column is-9">
            <div className="is-size-6 has-text-weight-light has-letter-spacing-1 is-uppercase has-text-primary">
              <p className="">Archiwum artykułów prasowych i wyróżnień</p>
              <p className="is-size-7">Plik PDF, wielkość 1,9 MB</p>
            </div>
          </div>
        </div>
      </a>

      <a
        href="do-pobrania/Wrutkowski_wystawy.pdf"
        target="_blank"
        className="has-text-centered"
      >
        <div class="columns has-text-centered is-vcentered is-mobile mb-6 is-gold-link has-text-primary">
          <div class="column is-3">
            <StaticImage
              src="../images/pdf-2.jpg"
              alt="Wystawy PDF"
              objectFit="contain"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
          </div>
          <div class="column is-9">
            <div className="is-size-6 has-text-weight-light has-letter-spacing-1 is-uppercase">
              <p>Archiwum wystaw i wernisaży</p>
              <p className="is-size-7">Plik PDF, wielkość 2,8 MB</p>
            </div>
          </div>
        </div>
      </a>

      <a
        href="do-pobrania/Wrutkowski_zdjecia.pdf"
        className="has-text-centered"
        target="_blank"
      >
        <div class="columns has-text-centered is-vcentered is-mobile mb-6 is-gold-link has-text-primary">
          <div class="column is-3">
            <StaticImage
              src="../images/pdf-3.jpg"
              alt="ZDJĘCIA PDF"
              objectFit="contain"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
          </div>
          <div class="column is-9">
            <div className="is-size-6 has-text-weight-light has-letter-spacing-1 is-uppercase">
              <p>Archiwum fotografii z wystaw i plenerów</p>
              <p className="is-size-7">Plik PDF, wielkość 1,1 MB</p>
            </div>
          </div>
        </div>
      </a>
    </>
  )
}

export default DoPobraniaTiles
