import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ShareButton from "../../components/share-button"
import BtnCircle from "../../components/button-circle"
import GalleryCategoryTilesMobile from "../../components/gallery-category-tiles-mobile"
import GalleryCategoryTiles from "../../components/gallery-category-tiles"
import DoPobraniaTiles from "../../components/do-pobrania-tiles"
import BtnLeft from "../../components/button-left"

const DoPobraniaIndex = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/do-pobrania/" }) {
        frontmatter {
          title
        }
        html
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { inGallery: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 10
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  `)
  const posts = pageData.allMarkdownRemark.nodes
  const pageTitle = pageData.markdownRemark.frontmatter.title
  const pageBody = pageData.markdownRemark.html
  const featuredImage = getImage(
    pageData.markdownRemark.frontmatter.featuredImage
  )

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Materiały do pobrania" />
        <div className="columns">
          <div className="column is-8-desktop is-12-tablet content-container has-background-dark is-6">
            <div className="columns is-mobile">
              <div className="column has-text-left ml-3">
                <BtnLeft
                  className=""
                  path={"/"}
                  description={"STRONA GŁÓWNA"}
                />
              </div>
              <div className="column has-text-right">
                <ShareButton location={location} />
              </div>
            </div>
            <section className="section has-text-justified has-text-weight-light is-size-5 pb-4 pt-3">
              <div
                className="md-has-dropcap md-article"
                dangerouslySetInnerHTML={{ __html: pageBody }}
              />
            </section>
            <section>
              <DoPobraniaTiles />
            </section>
            <section className="is-hidden-desktop">
              <GalleryCategoryTilesMobile />
            </section>
          </div>
          <div className="column is-offset-1 is-3 is-1-tablet is-hidden-touch">
            <GalleryCategoryTiles />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DoPobraniaIndex
